import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { PageLayout } from "../../layouts"
import { ListItem, Meta, UnorderedList } from "../../components"
import { styled } from '../../../stitches.config'

const Container = styled("section", {
  maxWidth: 600,
  length: 0
})

const BlogPage = ({ data }) => {
  return (
    <PageLayout>
      <Meta title="Blog" />
      {
        
        data.allMdx.nodes.map(node => (
          <Container>
          <UnorderedList>
          <article key={node.id}>
          <ListItem>
              <Link to={`/blog/${node.slug}`}>
                {node.frontmatter.title}
              </Link>
          </ListItem>
            <p>Posted: {node.frontmatter.date}</p>
          </article>
          </UnorderedList>
          </Container>
        ))
      }
    </PageLayout>
  )
}

export const query = graphql`
  query {
    allMdx(sort: {fields: frontmatter___date, order: DESC}) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
        }
        id
        slug
      }
    }
  }
`

export default BlogPage